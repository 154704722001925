import {
    Button,
    Flex,
    Heading,
    Image,
    Stack,
    Text,
  } from '@chakra-ui/react'

  // Assets
  // import Logo from "assets/img/logo.png";
  // import LogoFull from "assets/img/logoFull.png";
  import HeroImage from "assets/img/hero.png";
  import BackgroundHero from "assets/img/backgroundHero.png";
  
  export default function Hero() {
    const idAffiliate = window.location.href.split('?idAffiliate=')[1];
    const isSuccess = window.location.href.includes('sucesso');

    return (
        <Stack minH={isSuccess ? 'calc(80vh - 60px)' : 'calc(100vh - 60px)'} mb={50} direction={{ base: 'column', md: 'row' }}>
            <Flex p={8} pl={16} flex={1} justify={'space-between'} direction={'column'}>
                {/* <Stack direction={{ md: 'row' }} spacing={4} marginBottom="20px">
                    <Image
                        alt="Logo"
                        objectFit="contain"
                        src={Logo}
                        style={{ height: 32, width: 48, marginRight: 20 }}
                    />
                    <Image
                        alt="LogoFull"
                        objectFit="contain"
                        src={LogoFull}
                        style={{ height: 26, width: 158 }}
                    />
                </Stack> */}
                <Flex flex={1} justify={'center'} direction={'column'}>
                    <Stack spacing={6} w={'full'} maxW={'lg'}>
                        <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
                            <Text
                                // as={'span'}
                                // position={'relative'}
                                // _after={{
                                //     content: "''",
                                //     width: 'full',
                                //     height: useBreakpointValue({ base: '20%', md: '30%' }),
                                //     position: 'absolute',
                                //     bottom: 1,
                                //     left: 0,
                                //     bg: 'gray.300',
                                //     zIndex: -1,
                                // }}
                                style={{ fontWeight: 800, letterSpacing: 2, fontFamily: 'Encode Sans Condensed', fontSize: 22, borderLeft: '2px solid #59EBFD', paddingLeft: 10 }}
                                color="main.700"
                            >
                                {'Inteligência Artificial Exclusiva para Advogados e Advogadas'.toUpperCase()}
                            </Text>
                            <br />
                            <Text
                                color={'white'} 
                                style={{ fontWeight: 500, letterSpacing: 2, fontFamily: 'Encode Sans Condensed', fontSize: 32 }}
                            >
                                Você acaba de encontrar a <text style={{ color: '#59EBFD' }}>única I.A.</text> que produz documentos jurídicos com precisão, velocidade e excelência.
                            </Text>
                        </Heading>
                        <Text fontSize={{ base: 'md', lg: 'lg' }} color={'white'}>
                            A AdvTechPro.AI representa o fim de uma longa era onde Advogados e Advogadas precisavam passar horas e horas escrevendo documentos jurídicos ou pior, precisavam refazer documentos escritos por pessoas desqualificadas.
                        </Text>
                        <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
                            <form
                              onSubmit={(event) => {
                                event.preventDefault();
                                let baseURL = `https://plataforma.advtechpro.tech/#/auth/sign-in?idAffiliate=${idAffiliate || 'noIdAffiliate'}`;
                                window.open(baseURL);
                              }}
                            >
                              <Button
                                  bg={'main.700'}
                                  color={'#060A14'}
                                  borderRadius="5px"
                                  minH="56px"
                                  minW="190px"
                                  style={{ fontWeight: 600, fontSize: 17 }}
                                  type="submit"
                              >
                                  TESTE GRÁTIS AQUI POR 7 DIAS
                              </Button>
                            </form>
                            {/* <Button rounded={'full'}>Ver demo</Button> */}
                        </Stack>
                    </Stack>
                </Flex>
            </Flex>
            <Flex flex={1} align="center" display={{ base: "none", md: "flex" }}>
                <Flex position="relative" w="100%" h="100%">
                    <Image
                        src={BackgroundHero}
                        alt="BackgroundHero"
                        w="100%"
                        h="auto"
                        objectFit="contain"
                        position="absolute"
                        bottom="0px"
                        right="0px"
                        animation="flutuar 3s infinite alternate"
                    />
                    <Image
                        src={HeroImage}
                        alt="Imagem"
                        w="100%"
                        h="auto"
                        objectFit="contain"
                        position="absolute"
                        right="0px"
                        bottom="0px"
                        animation="flutuar 3s infinite alternate"
                    />
                </Flex>
            </Flex>
        </Stack>
    )
  }
