import { Box, Container, Stack, Text } from '@chakra-ui/react'

export default function Footer() {
  return (
    <Box
        bg="transparent"
        color="white"
        fontSize="16px"
        borderTop="1px solid #59EBFD"
        shadow="1px 1px 12px 0 #59EBFD"
    >
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}>
        <Text>ADVTECHPRO.AI</Text>
        <Stack direction={'row'} spacing={6}>
          <Box>
            Política de Privacidade
          </Box>
          <Box>
            Termos e condições
          </Box>
          <Box>
            Contato
          </Box>
        </Stack>
      </Container>
    </Box>
  )
}