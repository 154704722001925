import { api } from './api';

export async function getListArticles(page) {
  try {
    let baseURL = '/automarticlesArticles?limit=9';
    if (page) {
      baseURL = `${baseURL}&page=${page}`
    }
    const response = await api.get(baseURL, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response?.data;
  } catch (error) {
    const { response } = error;
    return response?.data;
  }
}
