import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    Button,
  } from '@chakra-ui/react';

  // assets
  import FeaturesTwo from "assets/img/featuresTwo.png";
  
  export default function FeaturesSecond() {
    const idAffiliate = window.location.href.split('?idAffiliate=')[1];

    const mainTags = [
        'Valorizam a produção de documentos jurídicos impecáveis;',
        'Enxergam a Inteligência Artificial como recurso necessário para o crescimento da sua Advocacia;',
        'Desejam ter mais tempo para trabalhar em suas estratégias jurídicas;',
        'Dão muita importância ao tempo livre e de qualidade para ficar com as pessoas que amam;',
        'Priorizam otimização do tempo, aperfeiçoamento prático do operacional e resultados.'
    ];
    return (
        <Container maxW={'90%'} marginTop="100px" marginBottom="100px">
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <Stack spacing={4}>
                    <Heading>
                        <Text
                            color={'white'} 
                            style={{ fontWeight: 700, fontFamily: 'Encode Sans Condensed', letterSpacing: 2, fontSize: 22 }}
                        >
                            Entenda se esta <text style={{ color: '#59EBFD' }}>Ferramenta de Inteligência Artificial</text> é para você.
                        </Text>
                    </Heading>
                    <Text style={{ fontFamily: 'Encode Sans Condensed', letterSpacing: 2, fontSize: 16 }} color={'white'} fontSize={'lg'}>
                        É para Advogados e Advogadas que:
                    </Text>
                    {mainTags.map((v) => (
                        <Stack key={v} direction={{ md: 'row', base: 'row' }} alignItems="center" spacing={2}>
                            <div style={{ minHeight: 12, minWidth: 12, border: '1.5px solid #59EBFD', borderRadius: '50%' }} />
                            <Text style={{ fontFamily: 'Encode Sans Condensed', letterSpacing: 2, fontSize: 16 }} color={'white'} fontSize={'lg'}>{v}</Text>
                        </Stack>
                    ))}
                    <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
                      <form
                        onSubmit={(event) => {
                          event.preventDefault();
                          let baseURL = `https://plataforma.advtechpro.tech/#/auth/sign-in?idAffiliate=${idAffiliate || 'noIdAffiliate'}`;
                          window.open(baseURL);
                        }}
                      >
                        <Button
                          bg={'main.700'}
                          color={'#060A14'}
                          borderRadius="5px"
                          minH="56px"
                          minW="190px"
                          style={{ fontWeight: 600, fontSize: 17 }}
                          type="submit"
                        >
                          TESTE GRÁTIS AQUI POR 7 DIAS
                        </Button>
                      </form>
                    </Stack>
                </Stack>
                <Flex justify="flex-end">
                    <Image
                        rounded={'md'}
                        alt={'feature image'}
                        src={FeaturesTwo}
                        objectFit={'cover'}
                        animation="flutuar 3s infinite alternate"
                    />
                </Flex>
            </SimpleGrid>
        </Container>
    )
  }
