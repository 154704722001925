import {
  Box,
  HStack,
  Heading,
  Text,
  VStack,
  List,
  Button,
  SimpleGrid,
  Stack,
  Icon,
  Tag,
} from '@chakra-ui/react'
import { useState } from 'react';
import { MdCheck, MdClose } from 'react-icons/md';

function PriceWrapper(props) {
  const { children } = props

  return (
    <Box
      mb={4}
      shadow="1px 1px 12px 0 #59EBFD"
      borderWidth="2px"
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={"#59EBFD"}
      borderRadius={'12px'}
      minH="488px"
    >
      {children}
    </Box>
  )
}

export default function Pricing() {
    const allDescriptions = [
        'Disponível 24 horas por dia, 7 dias por semana',
        'Até x peças disponíveis',
        'Interações via ChatAdvTech',
        'Entrada de comando por texto',
        'Entrada de comando por voz',
        'Cadastro de Autor e Réu',
        'Petições Iniciais',
        'Contestações',
        'Réplica',
        'Recursos',
        'Pareceres jurídicos',
        'Contratos',
        'Converse com o PDF',
        'Biblioteca de Prompts',
        'Suporte por e-mail em até x',
        'Suporte por WhatsApp em até 1 dia útil',
        'Elaboração de peças processuais com jurisprudências reais',
        'Participação da Comunidade Advocacia Com Inteligência Artificial - Com Prof. Túlio Silveira (até 2 profissionais por Escritório)',
    ];

    const allPlans = [
        {
            id: 1,
            idsPlans: ['$2a$10$O9vdb1W/KTF8cfFFKWGIRuPxw4f1LYCoXC9XtTOBydYIb6cULWcU6', '$2a$10$yPLldZXuYe0D7kCBxn87YObWewbl8wa5IttLxCflyajaw0MnMmvhO'],
            name: 'Plano Básico',
            plan: 'basic',
            priceMonthFinal: 9700,
            priceYearlyFinal: 104760,
            priceMonth: 'R$ 97,00',
            priceYearlyFull: 'R$ 1.164,00',
            priceYearly: 'R$ 1.047,60',
            description: [0, 1, 2, 3, 5, 6, 7, 8, 9, 14, 16],
            pecas: '30',
            day: 3,
        },
        {
            id: 2,
            idsPlans: ['$2a$10$teaJsmrfdE1q1m/YbmfodOVfJG4nS2vDnpls8Hqt9YBtc/1/jC/pq', '$2a$10$i5okQvA9axO6qhCGDJtWnOm2hgmujG0n905g69CYV5T1owyOC/D06'],
            name: 'Plano Advanced',
            plan: 'advanced',
            priceMonthFinal: 14700,
            priceYearlyFinal: 158760,
            priceMonth: 'R$ 147,00',
            priceYearlyFull: 'R$ 1.764,00',
            priceYearly: 'R$ 1.587,60',
            description: [0, 1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 14, 16],
            pecas: '60',
            day: 2,
        },
        {
            id: 3,
            idsPlans: ['$2a$10$vvqc3ude8updl0oGrgoh8e3kEwM3lhhd8GAtzkdpvYoI5ovwKr2K2', '$2a$10$Oe3xb1zXPC/xxgFpxezTfucxTFX/yilCDaloYRs8QxIe81ocHVaHu'],
            name: 'Plano Premium',
            plan: 'premium',
            priceMonthFinal: 24700,
            priceYearlyFinal: 266760,
            priceMonth: 'R$ 247,00',
            priceYearlyFull: 'R$ 2.964,00',
            priceYearly: 'R$ 2.667,60',
            description: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
            pecas: '120',
            day: 1,
        },
    ];

    const idAffiliate = window.location.href.split('?idAffiliate=')[1];

    const [typePlan, setTypePlan] = useState('anual');

    const stylesButtonPlan = {
        cursor: 'pointer',
        width: '45%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        height: 37,
        borderRadius: 8,
        letterSpacing: 2,
        fontFamily: 'Encode Sans Condensed',
        fontSize: 14,
        fontWeight: 500,
    };

  return (
    <Box marginBottom="100px" width="100%" display="flex" flexDirection="column" alignItems="center">
        <Heading>
            <Text
                color={'white'} 
                style={{ fontWeight: 700, letterSpacing: 2, fontFamily: 'Encode Sans Condensed', fontSize: 32 }}
            >
                Como funciona o Acesso a <text style={{ color: '#59EBFD' }}>Advtechpro.ai</text> por Planos?
            </Text>
            <Text
                color={'white'} 
                style={{ fontWeight: 700, letterSpacing: 2, fontFamily: 'Encode Sans Condensed', fontSize: 14, marginTop: 10 }}
            >
                Teste por 7 dias sem ter que inserir seus dados de pagamentos e veja o que a IA lhe oferece
            </Text>
        </Heading>
        <div style={{ backgroundColor: '#F5F5F5', borderRadius: 12, height: 53, width: 241, marginTop: 20, marginBottom: 40, display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
            <div
                style={{
                    ...stylesButtonPlan,
                    backgroundColor: typePlan === 'mensal' ? '#59EBFD' : 'transparent'
                }}
                onClick={() => setTypePlan('mensal')}
            >
                Mensal
            </div>
            <div
                style={{
                    ...stylesButtonPlan,
                    backgroundColor: typePlan === 'anual' ? '#59EBFD' : 'transparent'
                }}
                onClick={() => setTypePlan('anual')}
            >
                Anual
            </div>
        </div>
        <SimpleGrid
            w="90%" columns={{ base: 1, md: 3 }} spacing={{ base: 4, lg: 10 }} py={10}
        >
            {allPlans.map((v) => {
                return (
                    <PriceWrapper key={v.id}>
                        <Box py={4} px={12}>
                            {v.plan === 'advanced' ? (
                                <Stack display="flex" alignItems="flex-end">
                                    <Tag
                                        bg={'main.700'}
                                        color={'#060A14'}
                                        cursor="pointer"
                                    >
                                        Mais Contratado
                                    </Tag>
                                </Stack>
                            ) : null}
                            <Text fontWeight="500" fontSize="16px" color="white">
                                {v.name}
                            </Text>
                            {typePlan === 'mensal' ? (
                                <HStack>
                                    <Text color="white" style={{ fontWeight: 700, letterSpacing: 2, fontFamily: 'Encode Sans Condensed', fontSize: 32 }}>
                                        {v.priceMonth}
                                    </Text>
                                    <Text color="white" style={{ paddingTop: 15, letterSpacing: 2, fontFamily: 'Encode Sans Condensed', fontSize: 12 }}>
                                        por mês
                                    </Text>
                                </HStack>
                            ) : (
                                <>
                                    <HStack sx={{ textDecoration: 'line-through', textDecorationColor: '#59EBFD' }}>
                                        <Text color="white" style={{ fontWeight: 700, letterSpacing: 2, fontFamily: 'Encode Sans Condensed', fontSize: 20 }}>
                                            {v.priceYearlyFull}
                                        </Text>
                                        <Text color="white" style={{ letterSpacing: 2, fontFamily: 'Encode Sans Condensed', fontSize: 8 }}>
                                            por ano
                                        </Text>
                                    </HStack>
                                    <HStack>
                                        <Text color="white" style={{ fontWeight: 700, letterSpacing: 2, fontFamily: 'Encode Sans Condensed', fontSize: 32 }}>
                                            {v.priceYearly}
                                        </Text>
                                        <Text color="white" style={{ paddingTop: 15, letterSpacing: 2, fontFamily: 'Encode Sans Condensed', fontSize: 12 }}>
                                            por ano
                                        </Text>
                                    </HStack>
                                </>
                            )}
                        </Box>
                        <Box w="100%" pt={7} justifyContent="center" display="flex" alignItems="center">
                          <form
                            onSubmit={(event) => {
                              event.preventDefault();
                              let baseURL = `https://plataforma.advtechpro.tech/#/auth/sign-in?idAffiliate=${idAffiliate || 'noIdAffiliate'}&idPlan=${v.idsPlans[typePlan === 'mensal' ? 0 : 1]}`;
                              window.open(baseURL);
                            }}
                          >
                            <Button
                                w="80%"
                                minH="50px"
                                bg={'main.700'}
                                color={'#060A14'}
                                borderRadius="5px"
                                style={{ fontFamily: 'Encode Sans Condensed', letterSpacing: 2, fontWeight: 600, fontSize: 14, whiteSpace: 'normal' }}
                                type="submit"
                            >
                                TESTE GRÁTIS AQUI POR 7 DIAS
                            </Button>
                          </form>
                        </Box>
                        <VStack
                            bg="transparent"
                            py={8}
                            minHeight="400px"
                            justifyContent="space-between"
                        >
                            <List spacing={3} textAlign="start" px={12}>
                                {allDescriptions.map((e, i) => {
                                    if (i === 5 && typePlan === 'mensal') {
                                        return null;
                                    }
                                    return (
                                        <Stack key={e} direction={{ md: 'row', base: 'row' }} spacing={2}>
                                            {v.description.includes(i) ? (
                                                <Icon as={MdCheck} width='20px' height='20px' color='#59EBFD' mt={1} />
                                            ) : (
                                                <Icon as={MdClose} width='20px' height='20px' color="red" mt={1} />
                                            )}
                                            <Text style={{ fontFamily: 'Encode Sans Condensed', letterSpacing: 2, fontSize: 16 }} color={'white'}>
                                                {i === 1 ? e.replace('x', v.pecas) : (i === 14 ? e.replace('x', `${v.day} dia${v.day > 1 ? 's' : ''} ${v.day > 1 ? 'úteis' : 'útil'}`) : e)}
                                            </Text>
                                        </Stack>
                                    );
                                })}
                            </List>
                            <Box w="80%" pt={7} justifyContent="center" display="flex">
                              <form
                                onSubmit={(event) => {
                                  event.preventDefault();
                                  let baseURL = `https://plataforma.advtechpro.tech/#/auth/sign-in?idAffiliate=${idAffiliate || 'noIdAffiliate'}&idPlan=${v.idsPlans[typePlan === 'mensal' ? 0 : 1]}`;
                                  window.open(baseURL);
                                }}
                              >
                                <Button
                                  w="100%"
                                  minH="50px"
                                  bg={'main.700'}
                                  color={'#060A14'}
                                  borderRadius="5px"
                                  style={{ fontFamily: 'Encode Sans Condensed', letterSpacing: 2, fontWeight: 600, fontSize: 14, whiteSpace: 'normal' }}
                                  type="submit"
                                >
                                    TESTE GRÁTIS AQUI POR 7 DIAS
                                </Button>
                              </form>
                            </Box>
                        </VStack>
                    </PriceWrapper>
                );
            })}
        </SimpleGrid>
    </Box>
  )
}
