/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Heading,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  useColorModeValue,
  Container,
  VStack,
  Button,
} from '@chakra-ui/react';
import { format, fromUnixTime } from 'date-fns'
import { useEffect, useState } from 'react'
import { getListArticles } from 'services/blog'
import HTMLRenderer from 'react-html-renderer'
import Pagination from 'components/pagination';

const BlogTags = (props) => {
  const { marginTop = 0, tags } = props

  return (
    <HStack spacing={2} marginTop={marginTop}>
      {tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="cyan" key={tag}>
            {tag}
          </Tag>
        )
      })}
    </HStack>
  )
}

const BlogAuthor = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center" color="white">
      {/* <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      /> */}
      <Text fontWeight="medium">Publicado</Text>
      {/* <Text fontWeight="medium">{props.name}</Text> */}
      <Text>—</Text>
      <Text>{String(props?.date || '')}</Text>
    </HStack>
  )
}

const Blog = () => {
  const idAffiliate = window.location.href.split('?idAffiliate=')[1];
  const radialColor = useColorModeValue(
    'radial(white 1px, transparent 1px)',
    'radial(white 1px, transparent 1px)',
  );
  const [listArticles, setListArticles] = useState([]);
  const [firstAutomarticlesArticles, setFirstAutomarticlesArticles] = useState(null);
  const [infoBlog, setInfoBlog] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const getAllArticles = async () => {
    const response = await getListArticles(currentPage);
    setFirstAutomarticlesArticles(response?.result?.firstAutomarticlesArticles || null);
    setListArticles(response?.result?.list || []);
    setTotalPages(response?.result?.qtdPages || 1);
    window.scrollTo({
      top: 800,
      behavior: 'smooth'
    });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const isBlog = window.location.href === `${window.location.origin}/#/blog`;
    if (isBlog) {
      setInfoBlog(null);
    }
  }, [window.location.href]);

  useEffect(() => {
    getAllArticles();
  }, []);

  if (!firstAutomarticlesArticles?.id || !listArticles?.length) {
    return null;
  }

  if (infoBlog?.id) {
    return (
      <Container maxW={'7xl'} p="12">
        <Heading as="h1" color="white">Blog do ADVTECHPRO.AI</Heading>
        <Box
          marginTop={{ base: '1', sm: '5' }}
          display="flex"
          flexDirection={{ base: 'column', sm: 'column', lg: 'row' }}
          justifyContent="space-between">
          <Box
            display="flex"
            flex="1"
            marginRight="3"
            position="relative"
            alignItems="center">
            <Box
              width={{ base: '100%', sm: '85%' }}
              zIndex="2"
              marginLeft={{ base: '0', sm: '5%' }}
              marginTop="5%">
              <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
                <Image
                  borderRadius="lg"
                  transform="scale(1.0)"
                  src={infoBlog.article_featured_image_url}
                  alt={infoBlog.article_featured_image_alt_text}
                  objectFit="contain"
                  transition="0.3s ease-in-out"
                  _hover={{
                    transform: 'scale(1.05)',
                  }}
                />
              </Box>
            </Box>
            <Box zIndex="1" width="100%" position="absolute" height="100%">
              <Box
                bgGradient={radialColor}
                backgroundSize="20px 20px"
                opacity="0.4"
                height="100%"
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
            marginTop={{ base: '3', sm: '3' }}>
            <BlogTags tags={[infoBlog.article_category_name]} />
            <Heading marginTop="1">
              <Text textDecoration="none" color="white" _hover={{ textDecoration: 'none' }}>
                {infoBlog?.article_title}
              </Text>
            </Heading>
            <Text
              as="p"
              marginTop="2"
              color="white"
              fontSize="lg">
                {infoBlog?.article_description}
            </Text>
            <BlogAuthor date={format(fromUnixTime(Number(infoBlog.article_publication_date)), 'dd/MM/yyyy')} />
            <form
              onSubmit={(event) => {
                event.preventDefault();
                let baseURL = `https://plataforma.advtechpro.tech/#/auth/sign-in?idAffiliate=${idAffiliate || 'noIdAffiliate'}`;
                window.open(baseURL);
              }}
            >
              <Button
                fontSize={'sm'}
                fontWeight={600}
                bg={'main.700'}
                w={250}
                alignSelf="flex-end"
                mt={5}
                color={'#060A14'}
                type="submit">
                TESTE GRÁTIS AQUI POR 7 DIAS
              </Button>
            </form>
          </Box>
        </Box>
        <VStack paddingTop="40px" spacing="2" alignItems="flex-start" color="white">
          <HTMLRenderer
            html={infoBlog.article_content_html}
            components={{
              h1: props => <Heading color="white" {...props} />,
              h2: props => <Heading color="white" {...props} />,
              h3: props => <Heading color="white" {...props} />,
              h4: props => <Heading color="white" {...props} />,
              h5: props => <Heading color="white" {...props} />,
              title: props => <Heading color="white" {...props} />,
            }}
          />
        </VStack>
        <VStack>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              let baseURL = `https://plataforma.advtechpro.tech/#/auth/sign-in?idAffiliate=${idAffiliate || 'noIdAffiliate'}`;
              window.open(baseURL);
            }}
          >
            <Button
              fontSize={'sm'}
              fontWeight={600}
              bg={'main.700'}
              w={250}
              alignSelf="flex-end"
              mt={5}
              color={'#060A14'}
              type="submit">
              TESTE GRÁTIS AQUI POR 7 DIAS
            </Button>
          </form>
        </VStack>
      </Container>
    );
  }

  return (
    <Container maxW={'7xl'} p="12">
      <Heading as="h1" color="white">Blog do ADVTECHPRO.AI</Heading>
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        as={"a"}
        href={`/#/blog/${firstAutomarticlesArticles.id}`}
        onClick={() => setInfoBlog(firstAutomarticlesArticles)}
        flexDirection={{ base: 'column', sm: 'column', lg: 'row' }}
        justifyContent="space-between">
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center">
          <Box
            width={{ base: '100%', sm: '85%' }}
            zIndex="2"
            marginLeft={{ base: '0', sm: '5%' }}
            marginTop="5%">
            <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
              <Image
                borderRadius="lg"
                transform="scale(1.0)"
                src={firstAutomarticlesArticles.article_featured_image_url}
                alt={firstAutomarticlesArticles.article_featured_image_alt_text}
                objectFit="contain"
                width="100%"
                transition="0.3s ease-in-out"
                _hover={{
                  transform: 'scale(1.05)',
                }}
              />
            </Box>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              bgGradient={radialColor}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '3' }}>
          <BlogTags tags={[firstAutomarticlesArticles.article_category_name]} marginTop={3} />
          <Heading marginTop="1">
            <Text textDecoration="none" color="white" _hover={{ textDecoration: 'none' }}>
                {firstAutomarticlesArticles.article_title}
            </Text>
          </Heading>
          <Text
            as="p"
            marginTop="2"
            color="white"
            fontSize="lg">
            {firstAutomarticlesArticles.article_description}
          </Text>
          <BlogAuthor date={format(fromUnixTime(Number(firstAutomarticlesArticles.article_publication_date)), 'dd/MM/yyyy')} />
        </Box>
      </Box>
      <Heading as="h2" marginTop="5" color="white">
        Últimas novidades do Blog
      </Heading>
      <Divider marginTop="5" />
      <Wrap spacing="30px" marginTop="5" marginBottom="60px" color="white">
        {listArticles.map((article) => (
          <WrapItem key={article.id} as={"a"} href={`/#/blog/${article.id}`} onClick={() => setInfoBlog(article)} cursor="pointer" width={{ base: '100%', sm: '100%', md: '45%', lg: '30%' }}>
            <Box w="100%">
              <Box borderRadius="lg" overflow="hidden">
                <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
                  <Image
                    transform="scale(1.0)"
                    src={article.article_featured_image_url}
                    alt={article.article_featured_image_alt_text}
                    objectFit="contain"
                    width="100%"
                    transition="0.3s ease-in-out"
                    _hover={{
                      transform: 'scale(1.05)',
                    }}
                  />
                </Box>
              </Box>
              <BlogTags tags={[article.article_category_name]} marginTop={3} />
              <Heading fontSize="xl" marginTop="2">
                <Text textDecoration="none" _hover={{ textDecoration: 'none' }}>
                  {article.article_title}
                </Text>
              </Heading>
              <Text as="p" fontSize="md" marginTop="2">
                {article.article_description}
              </Text>
              <BlogAuthor date={format(fromUnixTime(Number(article.article_publication_date)), 'dd/MM/yyyy')} />
            </Box>
          </WrapItem>
        ))}
      </Wrap>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </Container>
  )
}

export default Blog;
