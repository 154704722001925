import {
    Container,
    SimpleGrid,
    Image,
    Heading,
    Text,
    Stack,
  } from '@chakra-ui/react';

  // assets
  import AboutImg from "assets/img/about.png";
  
  export default function About() {
    return (
        <Container maxW={'90%'} marginBottom="100px">
            <SimpleGrid display={{ base: "block", xl: "flex" }} spacing={10} alignItems="flex-start">
                <Stack spacing={4}>
                    <Heading>
                        <Text
                            color={'white'} 
                            style={{ fontWeight: 700, letterSpacing: 2, fontFamily: 'Encode Sans Condensed', fontSize: 22 }}
                        >
                            Quem é o <text style={{ color: '#59EBFD' }}>criador</text> dessa I.A.
                        </Text>
                    </Heading>
                    <Text style={{ fontSize: 16 }} color={'white'} fontSize={'lg'}>
                      Meu nome é Túlio Silveira, Advogado, treinador de advogados e inovador no uso da Inteligência Artificial para alavancar escritórios de advocacia.
                    </Text>
                    <Text style={{ fontSize: 16 }} color={'white'} fontSize={'lg'}>
                      Minha história com a IA não começou com o recente fascínio pelo ChatGPT, nem mesmo nesta década. 
                    </Text>
                    <Text style={{ fontSize: 16 }} color={'white'} fontSize={'lg'}>
                      Na verdade, minha jornada teve início nos anos 90, durante minha experiência inicial na ciência da computação. 
                    </Text>
                    <Text style={{ fontSize: 16 }} color={'white'} fontSize={'lg'}>
                      Sim, essa foi minha primeira graduação, e embora não tenha concluído, cada momento daquela experiência se entrelaça com o meu caminho atual de maneiras surpreendentes.
                    </Text>
                    <Text style={{ fontSize: 16 }} color={'white'} fontSize={'lg'}>
                      A vida tem dessas reviravoltas inesperadas. Um ano e meio após iniciar a ciência da computação, encontrei-me vivendo em uma república repleta de estudantes de direito.
                    </Text>
                    <Text style={{ fontSize: 16 }} color={'white'} fontSize={'lg'}>
                      Foi ali, cercado por futuros advogados, que senti um forte chamado para o Direito. Ignorando os apelos de minha família para concluir a primeira graduação, mergulhei de cabeça em uma nova aventura acadêmica, desbravando o universo jurídico.
                    </Text>
                    <Text style={{ fontSize: 16 }} color={'white'} fontSize={'lg'}>
                      Essa decisão, embora controversa, forjou o profissional que sou hoje.
                    </Text>
                    <Text style={{ fontSize: 16 }} color={'white'} fontSize={'lg'}>
                      Avançando para 2018, um amigo me reintroduziu à IA, sugerindo sua aplicação em licitações, minha especialidade. Essa conversa reacendeu minha paixão pela tecnologia e marcou o início de um novo capítulo em minha carreira.
                    </Text>
                    <Text style={{ fontSize: 16 }} color={'white'} fontSize={'lg'}>
                      À medida que a IA ganhou popularidade no Brasil, especialmente no final de 2022, percebi seu imenso potencial e também suas armadilhas, particularmente no campo jurídico.
                    </Text>
                    <Text style={{ fontSize: 16 }} color={'white'} fontSize={'lg'}>
                      Muitos advogados se aventuraram no uso de ferramentas como o ChatGPT para a produção de documentos jurídicos, enfrentando desafios que poderiam ser evitados com uma abordagem mais refinada e especializada.
                    </Text>
                    <Text style={{ fontSize: 16 }} color={'white'} fontSize={'lg'}>
                      Embora ciente de que a IA não é o caminho para todos, estou comprometido em desenvolver soluções que aliam precisão, velocidade e excelência na produção de documentos jurídicos.
                    </Text>
                    <Text style={{ fontSize: 16 }} color={'white'} fontSize={'lg'}>
                      Não se trata apenas de adotar a tecnologia, mas de moldá-la para servir à Advocacia com excelência.
                    </Text>
                    <Text style={{ fontSize: 16 }} color={'white'} fontSize={'lg'}>
                      Assim, enquanto estou nos palcos dos eventos ou ministrando aulas online, compartilho não apenas o meu conhecimento sobre IA, mas também a minha jornada — uma história de descobertas, mudanças e a crença inabalável no poder da tecnologia aliada ao saber jurídico.
                    </Text>
                    <Text style={{ fontSize: 16 }} color={'white'} fontSize={'lg'}>
                      Mas acredite: eu não quero formar todos os Advogados e Advogadas para o uso da IA.
                    </Text>
                    <Text style={{ fontSize: 16 }} color={'white'} fontSize={'lg'}>
                      Sei que muitas pessoas não encontram nessa realidade sua zona de genialidade. E tudo bem.
                    </Text>
                    <Text style={{ fontSize: 16 }} color={'white'} fontSize={'lg'}>
                      Por isso decidi desenvolver uma ferramenta de Inteligência Artificial do zero, para produzir documentos jurídicos com PRECISÃO, VELOCIDADE E EXCELÊNCIA.
                    </Text>
                    <Text style={{ fontSize: 16 }} color={'white'} fontSize={'lg'}>
                      Agora ela está disponível para você, depois de milhares de testes e validações.
                    </Text>
                </Stack>
                <Image
                    rounded={'md'}
                    alt={'feature image'}
                    src={AboutImg}
                    objectFit={'contain'}
                    mt={{ base: 10, xl: 0 }}
                    animation="flutuar 3s infinite alternate"
                />
            </SimpleGrid>
        </Container>
    )
  }
