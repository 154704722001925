import {
    Box,
    Flex,
    Heading,
    Text,
    Stack,
    Container,
    Avatar,
    Grid,
    GridItem,
  } from '@chakra-ui/react'
  
  // assets
  import TestimonialImg1 from "assets/img/testimonial/testimonial_1.png";
  import TestimonialImg2 from "assets/img/testimonial/testimonial_2.png";
  import TestimonialImg3 from "assets/img/testimonial/testimonial_3.png";
  import TestimonialImg4 from "assets/img/testimonial/testimonial_4.png";
  import TestimonialImg5 from "assets/img/testimonial/testimonial_5.png";

  const Testimonial = (props) => {
    const { children } = props
  
    return <Box>{children}</Box>
  }
  
  const TestimonialContent = (props) => {
    const { children } = props
  
    return (
      <Stack
        bg="transparent"
        boxShadow={'lg'}
        p={8}
        rounded={'xl'}
        align={'center'}
        pos={'relative'}
        shadow="1px 1px 12px 0 #59EBFD"
        border="2px solid #59EBFD"
        _after={{
          content: `""`,
          w: 0,
          h: 0,
          borderLeft: 'solid transparent',
          borderLeftWidth: 16,
          borderRight: 'solid transparent',
          borderRightWidth: 16,
          borderTop: 'solid',
          borderTopWidth: 16,
          borderTopColor: '#59EBFD',
          pos: 'absolute',
          bottom: '-16px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}>
        {children}
      </Stack>
    )
  }
  
  // const TestimonialHeading = (props) => {
  //   const { children } = props
  
  //   return (
  //     <Heading as={'h3'} fontSize={'xl'} color="white" style={{ letterSpacing: 2, fontFamily: 'Encode Sans Condensed'}}>
  //       {children}
  //     </Heading>
  //   )
  // }
  
  const TestimonialText = (props) => {
    const { children } = props
  
    return (
      <Text
        textAlign={'center'}
        color="white"
        fontSize={'sm'}
        style={{ letterSpacing: 2, fontFamily: 'Encode Sans Condensed'}}
    >
        {children}
      </Text>
    )
  }
  
  const TestimonialAvatar = ({
    src,
    name,
    title,
  }) => {
    return (
      <Flex align={'center'} mt={8} direction={'column'}>
        <Avatar src={src} mb={2} />
        <Stack spacing={-1} align={'center'}>
          <Text fontSize="16px" color="white" style={{ letterSpacing: 2, fontFamily: 'Encode Sans Condensed'}}>{name}</Text>
          <Text fontSize={'12px'} color="white" style={{ letterSpacing: 2, fontFamily: 'Encode Sans Condensed'}}>
            {title}
          </Text>
        </Stack>
      </Flex>
    )
  }
  
  export default function Testimonials() {
    return (
      <Box marginBottom="100px">
        <Container maxW={'7xl'} as={Stack} spacing={12}>
            <Heading alignSelf="center">
                <Text
                    color={'white'} 
                    style={{ fontWeight: 700, letterSpacing: 2, fontFamily: 'Encode Sans Condensed', fontSize: 32 }}
                >
                    O que nossos <text style={{ color: '#59EBFD' }}>clientes</text> falam?
                </Text>
            </Heading>
            <Stack spacing={10}>
              <Grid
                templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
                gap={{ base: 10, md: 6, lg: 10 }}
              >
                <GridItem>
                  <Testimonial>
                    <TestimonialContent>
                      {/* <TestimonialHeading>Depoimento 1</TestimonialHeading> */}
                      <TestimonialText>
                        Como advogada com 15 anos de experiência, sempre busco ferramentas que otimizem a operação do meu escritório. Experimentei o ADVTECHPRO e ele
                        superou as minhas expectativas. Muito didático e fácil de usar! Conseguimos realizar peticionamentos de maneira rápida e eficiente, apenas inserindo
                        os dados básicos ou a petição a que se refere. Nos liberou mais tempo para focarmos em estratégias jurídicas e atendimento ao cliente. Recomendo a
                        todos os advogados que desejam otimizar seus processos e aumentar a produtividade!
                      </TestimonialText>
                    </TestimonialContent>
                    <TestimonialAvatar
                      src={TestimonialImg1}
                      name={'Bruna Caram'}
                      title={'Advogada'}
                    />
                  </Testimonial>
                </GridItem>
                <GridItem>
                  <Testimonial>
                    <TestimonialContent>
                      {/* <TestimonialHeading>Depoimento 2</TestimonialHeading> */}
                      <TestimonialText>
                        Trabalho como coordenadora operacional em um escritório há alguns anos, e a rotina de lidar diretamente com petições e correções de prazos sempre foi
                        muito intensa, pois temos uma grande banca. Recentemente, começamos a usar o ADVTECHPRO, e a mudança foi incrível. É extremamente intuitivo e
                        facilitou muito o nosso dia a dia, evitando tarefas repetitivas. Recomendo o ADVTECHPRO a todos os escritórios de advocacia que desejam otimizar
                        seus processos e melhorar a produtividade. É uma ferramenta essencial para quem busca eficiência e qualidade no trabalho diário.
                      </TestimonialText>
                    </TestimonialContent>
                    <TestimonialAvatar
                      src={TestimonialImg2}
                      name={'Mariane Pereira'}
                      title={'Advogada'}
                    />
                  </Testimonial>
                </GridItem>
                <GridItem>
                  <Testimonial>
                    <TestimonialContent>
                      {/* <TestimonialHeading>Depoimento 3</TestimonialHeading> */}
                      <TestimonialText>
                        Desde que comecei a usar o ADVTECHPRO, minha produtividade aumentou significativamente, pois a parte que cabe a mim é apenas a de deixar a peça
                        mais perfeita, vez que os argumentos todos são desenvolvidos pela automação. O grande diferencial é a separação em setores de inicial, defesa e
                        recursos, tornando a resposta da automação super específica, com a correta elaboração de preliminares e pedidos próprios de cada fase processual.
                        Sensacional!!!
                      </TestimonialText>
                    </TestimonialContent>
                    <TestimonialAvatar
                      src={TestimonialImg3}
                      name={'Nathalia Nunes'}
                      title={'Advogada'}
                    />
                  </Testimonial>
                </GridItem>
                <GridItem>
                  <Testimonial>
                    <TestimonialContent>
                      {/* <TestimonialHeading>Depoimento 4</TestimonialHeading> */}
                      <TestimonialText>
                        Como advogado recém-formado, o ADVTECHPRO tem sido essencial no meu dia a dia. Ele me ajuda a elaborar a primeira versão das minhas petições de
                        forma rápida, permitindo que eu me concentre apenas em lapidar os detalhes. O grande diferencial é o desbloqueio criativo dos parágrafos e teses.
                        Recomendo a todos os colegas que buscam otimizar seu trabalho e aumentar a produtividade.
                      </TestimonialText>
                    </TestimonialContent>
                    <TestimonialAvatar
                      src={TestimonialImg4}
                      name={'Rafael Costa'}
                      title={'Advogado'}
                    />
                  </Testimonial>
                </GridItem>
              </Grid>
              <Stack
                spacing={10}
                w={{ base: '100%', md: '50%' }}
                alignSelf="center"
              >
                <Testimonial>
                  <TestimonialContent>
                    {/* <TestimonialHeading>Depoimento 5</TestimonialHeading> */}
                    <TestimonialText>
                        Já algum tempo inclui a IA em meu escritório. Mais quando comecei usar a ADVTECHPRO há 3 meses, participando do programa de testes da ferramenta,
                        te confesso que de todas as IAs que testei e utilizei, essa é a mais completa para a equipe do meu escritório. Literalmente tenho uma assistente
                        jurídica com conhecimento muito amplo, rápido, com acesso a Jurisprudências reais. Além de ter a opção de já incluir vários dados de processos e
                        clientes, ela é muito didática, de fácil manuseio, e te entrega a peça de forma muito rápida e literalmente como você precisa para simplesmente dar
                        o feedback final e já protocolar. A minha ADVTECHPRO, é o funcionário do mês desde que comecei a usar.
                    </TestimonialText>
                  </TestimonialContent>
                  <TestimonialAvatar
                    src={TestimonialImg5}
                    name={'Rudisley Medeiros'}
                    title={'Advogado'}
                  />
                </Testimonial>
              </Stack>
            </Stack>
        </Container>
      </Box>
    )
  }