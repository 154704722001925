import { FaDownLong } from "react-icons/fa6";
import {
  Container,
  Text,
} from '@chakra-ui/react';
import { Icon } from "@chakra-ui/react";

export default function Congratulations() {
  return (
      <Container
        maxW={'100%'}
        bgColor="red.600"
        minHeight="20vh"
        borderBottom="3px solid white"
        alignItems="center"
        display="flex"
        justifyContent="space-between"
      >
        <Icon as={FaDownLong} width='100px' height='100px' color='white' />
        <Text
          style={{ fontFamily: 'Encode Sans Condensed', letterSpacing: 2 }}
          color={'white'}
          fontSize="3xl"
          fontWeight="bold"
          textAlign="center"
        >
          OBRIGADO PELA SUA AQUISIÇÃO. NÃO FECHE ESSA PÁGINA SEM LER O QUE TENHO PARA LHE APRESENTAR - TESTE GRÁTIS POR 7 DIAS ESSA INTELIGÊNCIA ARTIFICIAL EXCLUSIVA PARA ADVOGADOS E ADVOGADAS
        </Text>
        <Icon as={FaDownLong} width='100px' height='100px' color='white' />
      </Container>
  )
}
