import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    Button,
  } from '@chakra-ui/react';

  // assets
  import FeaturesOne from "assets/img/featuresOne.png";
  
  export default function Features() {
    const idAffiliate = window.location.href.split('?idAffiliate=')[1];

    const mainTags = ['PETIÇÕES', 'CONTESTAÇÕES', 'RECURSOS'];
    const allTags = ['Contratos', 'Acordos', 'Pareceres Jurídicos', 'Notificações e Cartas Jurídicas', 'Testamentos', 'Planejamentos Patrimoniais', 'Peças de Defesa', 'Atas e Estatuto', 'Memorandos Internos'];
    return (
        <Container maxW={'90%'}>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <Flex>
                    <Image
                        rounded={'md'}
                        alt={'feature image'}
                        src={FeaturesOne}
                        objectFit={'cover'}
                        animation="flutuar 3s infinite alternate"
                    />
                </Flex>
                <Stack spacing={4}>
                    <Heading>
                        <Text
                            color={'white'} 
                            style={{ fontWeight: 700, letterSpacing: 2, fontFamily: 'Encode Sans Condensed', fontSize: 22 }}
                        >
                            Entenda o que a <text style={{ color: '#59EBFD' }}>Ferramenta de Inteligência Artificial</text> que desenvolvemos, testamos e validamos é capaz de produzir com extrema qualidade:
                        </Text>
                    </Heading>
                    {mainTags.map((v) => (
                        <Stack key={v} direction={{ md: 'row', base: 'row' }} alignItems="center" spacing={2}>
                            <div style={{ minHeight: 12, minWidth: 12, border: '1.5px solid #59EBFD', borderRadius: '50%' }} />
                            <Text style={{ fontFamily: 'Encode Sans Condensed', letterSpacing: 2, fontSize: 18, fontWeight: 'bold' }} color={'white'} fontSize={'lg'}>{v}</Text>
                        </Stack>
                    ))}
                    <div style={{ display: 'flex', alignItems: 'center', flexWrap:'wrap' }}>
                        {allTags.map((v) => (
                            <div key={v} style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                                <div style={{ height: 12, width: 12, border: '1.5px solid #59EBFD', borderRadius: '50%', marginRight: 5 }} />
                                <Text style={{ fontFamily: 'Encode Sans Condensed', letterSpacing: 2, fontSize: 16 }} color={'white'} fontSize={'lg'}>{v}</Text>
                            </div>
                        ))}
                    </div>
                    <Text style={{ fontFamily: 'Encode Sans Condensed', letterSpacing: 2, fontSize: 16 }} color={'white'} fontSize={'lg'}>
                        E todo documento jurídico que um escritório de Advocacia precisa.
                    </Text>
                    <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
                      <form
                        onSubmit={(event) => {
                          event.preventDefault();
                          let baseURL = `https://plataforma.advtechpro.tech/#/auth/sign-in?idAffiliate=${idAffiliate || 'noIdAffiliate'}`;
                          window.open(baseURL);
                        }}
                      >
                        <Button
                          bg={'main.700'}
                          color={'#060A14'}
                          borderRadius="5px"
                          minH="56px"
                          minW="190px"
                          style={{ fontWeight: 600, fontSize: 17 }}
                          type="submit"
                        >
                          TESTE GRÁTIS AQUI POR 7 DIAS
                        </Button>
                      </form>
                    </Stack>
                </Stack>
            </SimpleGrid>
        </Container>
    )
  }
