import React from 'react';
import Hero from 'sections/hero';
import Pricing from 'sections/pricing';
import Features from 'sections/features';
import FeaturesSecond from 'sections/FeaturesSecond';
import About from 'sections/about';
import Testimonials from 'sections/testimonials';
import Footer from 'sections/footer';
import Congratulations from 'sections/congratulations';
import WithSubnavigation from 'sections/navbar';
import Blog from 'sections/blog';

// Custom Chakra theme
export default function Dashboard(props) {
  const isBlog = window.location.href.includes('blog');
    return (
        <div style={{ backgroundColor: '#000000', letterSpacing: 2, fontFamily: 'Encode Sans Condensed' }}>
            <WithSubnavigation />
            {isBlog ? (
              <>
                <Hero />
                <Blog />
              </>
            ) : (
              <>
                {!window.location.href.includes('sucesso') ? null : (
                  <Congratulations />
                )}
                <Hero />
                <Features />
                <FeaturesSecond />
                <About />
                <Pricing />
                <Testimonials />
              </>
            )}
            <Footer />
        </div>
	);
}
